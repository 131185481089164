<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <div class="pa-6">
        <div class="d-flex mb-4">
          <span class="text-h6">Rejeitar KYC</span>

          <v-spacer />

          <v-btn icon @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-form v-model="valid" class="mb-2" ref="form">
          <custom-text-area
            ref="description"
            label="Motivo da rejeição"
            placeholder="Digite o motivo da rejeição"
            v-model="form.description"
            required
          />
        </v-form>

        <div class="d-flex justify-end">
          <custom-bttn
            class="mr-2"
            color="black"
            label="Cancelar"
            plain
            small
            @click="close()"
          />
          <custom-bttn label="Confirmar" small @click="submit()" />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      valid: false,
      form: {
        description: "",
      },
    };
  },

  methods: {
    submit() {
      if (!this.$refs.form.validate()) return;

      this.$emit("submit", this.form);
      this.close();
    },

    open() {
      this.dialog = true;

      setTimeout(() => {
        this.startForm();
      });
    },

    startForm() {
      this.$refs.description.handleInput("");
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
