<template>
  <v-card class="shadow rounded-lg">
    <section class="d-flex flex-column px-4 pt-2 pb-4">
      <div class="d-flex align-center mb-2">
        <div v-if="!!title" class="text-h6 overtext--text">
          <span class="work-sans">{{ title }}</span>
        </div>
      </div>

      <div v-if="list.length > 0">
        <v-list-item v-for="(item, i) in list" :key="i">
          <v-list-item-content v-for="(subitem, j) in item.items" :key="j">
            <v-list-item-title>
              <span v-if="item.mask">
                <input
                  v-model="subitem.value"
                  v-mask="subitem.mask"
                  class="custom-input"
                  readonly
                />
              </span>

              <span v-else>
                {{ subitem.value }}
              </span>
            </v-list-item-title>

            <v-list-item-subtitle>
              {{ subitem.label }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>

      <div v-else class="d-flex text-center justify-center">
        <span class="text-body-2 subtext--text">
          Nenhum dado a ser exibido
        </span>
      </div>
    </section>
  </v-card>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },

    list: {
      type: Array,
      default: [],
    },
  },

  directives: { mask },
};
</script>

<style lang="scss" scoped>
.custom-input:focus {
  outline: none !important;
  border: 0px;
}
</style>
