<template>
  <div>
    <v-card
      v-if="_validationStatus !== 'undefined'"
      class="mb-6 pa-4"
      color="grey lighten-4"
      flat
    >
      <div
        v-if="_validationStatus === 'waiting'"
        class="d-flex flex-column flex-md-row"
      >
        <div class="text-body-1 mb-4 mb-md-0">
          Perfil KYC aguardando <b>validação</b>!
        </div>

        <v-spacer />

        <div class="d-flex justify-end">
          <v-btn
            class="mr-2 text-none"
            color="red"
            depressed
            dark
            small
            @click="handleReject()"
          >
            Rejeitar
          </v-btn>

          <v-btn
            class="text-none"
            color="green"
            depressed
            dark
            small
            @click="submitStatus('1')"
            >Aprovar</v-btn
          >
        </div>
      </div>

      <div v-if="_validationStatus === 'rejected'">
        KYC <b class="red--text">reprovado!</b> Aguardando novo envio do usuário
        para analise.
      </div>

      <div v-if="_validationStatus === 'approved'">
        KYC <b class="green--text">aprovado</b>
      </div>
    </v-card>

    <v-row>
      <v-col cols="12" md="6">
        <DataCard
          title="Dados Pessoais"
          :list="_personalData"
          :action="actionProof"
          @submit:action="handlePersonalReceipt()"
        />
      </v-col>

      <v-col cols="12" md="6">
        <DataCard
          title="Dados Residenciais"
          :list="_addressData"
          :action="actionProof"
          @submit:action="handleAddressReceipt()"
        />
      </v-col>

      <v-col cols="12" md="6">
        <ListCard title="Dados Bancários" :list="_bankData" />
      </v-col>
    </v-row>

    <RejectDialog
      ref="rejectDialog"
      @submit="submitStatus('-1', $event.description)"
    />

    <loader-hover v-if="loading" />
  </div>
</template>

<script>
import { request } from "@/services";
import DataCard from "@/components/user/DataCard.vue";
import ListCard from "@/components/user/ListCard.vue";
import RejectDialog from "@/components/user/RejectDialog.vue";
import { displayAlert, currencyMask } from "@/utils";

export default {
  data() {
    return {
      loading: false,
      actionProof: {
        label: "Comprovate",
        icon: "mdi-magnify",
      },
    };
  },

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  components: {
    DataCard,
    ListCard,
    RejectDialog,
  },

  computed: {
    _personalData() {
      if (Object.keys(this.user.dadosPessoais).length > 0)
        return [
          {
            label: "Nome/Razão Social",
            value: this.user.dadosPessoais.nome_razao,
          },
          {
            label: "CPF/CNPJ",
            value: this.user.dadosPessoais.cpfCnpj,
            mask: ["###.###.###-##", "##.###.###/####-##"],
          },
          {
            label: "Data de nascimento",
            value: this.user.dadosPessoais.dataNascimento,
            mask: "##/##/####",
          },
          {
            label: "Profissão",
            value: this.user.dadosPessoais.profissao,
          },
          {
            label: "Telefone",
            value: this.user.dadosPessoais.telefone,
            mask: "(##) #####-####",
          },
          {
            label: "Renda total familiar",
            value: currencyMask(this.user.dadosPessoais.estRenda) + " BRL",
          },
          {
            label: "Liquidez",
            value: this.handleLiquidity(this.user.dadosPessoais.liquidez),
          },
          {
            label: "Preferencia de contato",
            value: this.handleContact(this.user.dadosPessoais.prefContato),
          },
        ];

      return [];
    },

    _addressData() {
      if (Object.keys(this.user.dadosResidencial).length > 0)
        return [
          {
            label: "Estado",
            value: this.user.dadosResidencial.estado,
          },
          {
            label: "Cidade",
            value: this.user.dadosResidencial.cidade,
          },
          {
            label: "CEP",
            value: this.user.dadosResidencial.cep,
            mask: "#####-###",
          },
          {
            label: "Bairro",
            value: this.user.dadosResidencial.bairro,
          },
          {
            label: "Rua",
            value: this.user.dadosResidencial.rua,
          },
          {
            label: "Número",
            value: this.user.dadosResidencial.numero,
          },

          {
            label: "Complemento",
            value: this.user.dadosResidencial.complemento,
          },
        ];

      return [];
    },

    _bankData() {
      if (this.user.chavesBancarias.length > 0)
        return this.user.chavesBancarias.map((el) => {
          return {
            items: [
              {
                label: "Chave",
                value: el.tipoChave.replace("Chave ", ""),
              },
              {
                label: "Conta",
                value: el.chave,
              },
            ],
          };
        });

      return [];
    },

    _validationStatus() {
      if (
        this.user.dadosPessoais?.status === "-1" &&
        this.user.dadosResidencial?.status === "-1"
      )
        return "rejected";
      else if (
        this.user.dadosPessoais?.status === "0" &&
        this.user.dadosResidencial?.status === "0"
      )
        return "waiting";
      else if (
        this.user.dadosPessoais?.status === "1" &&
        this.user.dadosResidencial?.status === "1"
      )
        return "approved";

      return "undefined";
    },
  },

  methods: {
    async submitStatus(status, observation = "") {
      this.loading = true;

      try {
        await this.personalSubmit(status, observation);
        await this.addressSubmit(status, observation);

        this.$emit("refresh");
      } catch (err) {
        this.displayAlert(err, 1);
      } finally {
        this.loading = false;
      }
    },

    async personalSubmit(status, observation = "") {
      const payload = {
        method: "changeUserDataStatus",
        userID: this.user.id,
        statusCode: status,
        obs: observation,
      };

      await request(payload).then(async (res) => {
        this.displayAlert(res.message);
        this.$emit("submit");
      });
    },

    async addressSubmit(status, observation = "") {
      const payload = {
        method: "changeUserResidencialStatus",
        userID: this.user.id,
        statusCode: status,
        obs: observation,
      };

      await request(payload).then(async (res) => {
        this.displayAlert(res.message);
        this.$emit("submit");
      });
    },

    handleReject() {
      this.$refs.rejectDialog.open();
    },

    handleLiquidity(value) {
      const values = [
        {
          label: "Mensal",
          value: "monthly",
        },
        {
          label: "Semestral",
          value: "semiannual",
        },
        {
          label: "Anual",
          value: "yearly",
        },
      ];

      const finded = values.find((el) => el.value === value);
      return finded ? finded.label : "Não definido";
    },

    handleContact(value) {
      const values = [
        {
          label: "E-mail",
          value: "email",
        },
        {
          label: "Celular",
          value: "phone",
        },
        {
          label: "Whatsapp",
          value: "whatsapp",
        },
      ];

      const finded = values.find((el) => el.value === value);
      return finded ? finded.label : "Não definido";
    },

    handlePersonalReceipt() {
      const url =
        this.$store.getters.apiURL + this.user.dadosPessoais.docfrente;

      window.open(url, "_blank");
    },

    handleAddressReceipt() {
      const url =
        this.$store.getters.apiURL + this.user.dadosResidencial.comprovante;

      window.open(url, "_blank");
    },

    displayAlert,
  },
};
</script>

<style></style>
