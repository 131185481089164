<template>
  <v-card class="shadow rounded-lg">
    <section class="d-flex flex-column px-4 pt-2 pb-4">
      <div
        v-if="!!title || Object.keys(action).length > 0"
        class="d-flex align-center mb-2"
      >
        <div v-if="!!title" class="text-h6 overtext--text">
          <span class="work-sans">{{ title }}</span>
        </div>

        <v-spacer />

        <v-btn
          v-if="Object.keys(action).length > 0 && list.length > 0"
          class="text-none"
          color="primary"
          depressed
          small
          @click="actionSubmit()"
        >
          <v-icon v-if="action.icon" left>{{ action.icon }}</v-icon>
          <span v-if="action.label">{{ action.label }}</span>
        </v-btn>
      </div>

      <div v-if="list.length > 0">
        <v-list-item v-for="(item, i) in list" :key="i">
          <v-list-item-content>
            <v-list-item-title>
              <span v-if="item.mask">
                <input
                  v-model="item.value"
                  v-mask="item.mask"
                  class="custom-input"
                  readonly
                />
              </span>

              <span v-else>
                {{ item.value }}
              </span>
            </v-list-item-title>

            <v-list-item-subtitle>
              {{ item.label }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>

      <div v-else class="d-flex text-center justify-center">
        <span class="text-body-2 subtext--text">
          Nenhum dado a ser exibido
        </span>
      </div>
    </section>
  </v-card>
</template>

<script>
import { mask } from "vue-the-mask";

export default {
  props: {
    title: {
      type: String,
      default: "",
    },

    list: {
      type: Array,
      default: [],
    },

    action: {
      type: Object,
      default: {},
    },
  },

  directives: { mask },

  methods: {
    actionSubmit() {
      this.$emit("submit:action");
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-input:focus {
  outline: none !important;
  border: 0px;
}
</style>
